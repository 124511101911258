#footer{
  width: 100%;
  min-height: 20vh;
  background: rgb(4,4,6);
  background: linear-gradient(90deg, rgba(4,4,6,1) 16%, rgba(23,17,23,0.9949229691876751) 45%, rgba(24,12,19,1) 100%, rgba(68,42,48,0.7484243697478992) 100%);
  .footer{
    display: flex;
    align-items:center;
    justify-content: center;
    &--title{
      margin: 5% 0 0 0 ;
      &__bio{
        color: white;
        font-weight: 500;
        font-family: "Poor Richard",sans-serif;
        font-size: 20px;
        margin: 0 20px;
      }
      &__dance{
        color: white;
        font-weight: 500;
        font-family: "Poor Richard",sans-serif;
        font-size: 20px;
      }
      &__whatsapp{
        margin-left: 20px;
        color: forestgreen;
        font-size: 20px;
      }
      &__telegram{
        margin-left: 20px;
        color: white;
        font-size: 20px;
      }
      &__instagram{
        margin-left: 20px;
        color: rgba(119, 34, 34, 0.89);
        font-size: 20px;
      }
    }
  }
}