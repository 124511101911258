#biography{
  background-image:url("https://temir.ae/_next/static/images/gifka-0a9217a2e87f69a2da74a784ba8deaac.gif") ;
  height: 90vh;
  background-size: cover;
  background-position: center ;
  background-repeat: no-repeat;
  .biography{
   display: flex;
    align-items: center;
    justify-content: center;
    img{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10% 0;
      border-radius: 10px;
      width: 300px;
      height: 300px;
    }
  }
}
#bioSecond{
  padding: 30px 0;
  background: black;
  .bioSecond{
    &--img{
      display: flex;
      overflow-x: auto;

      &::-webkit-scrollbar{
        display: none;
      }
      img{
        margin: 0 10px;
        border-radius: 10px;
        width: 100%;
        height: 300px;
      }
    }
  }
}