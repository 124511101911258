#hero{
  background: url("../../image/milana.jpg") repeat center/cover ;
  background-attachment: fixed;
  animation: animated-bg 30s linear infinite;
  min-height: 100vh;
  .hero{
    padding: 40px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &--title{
     &__img{
       width: 100%;
       height: 300px;
       border-radius: 10px;
       animation:  drone 2s ease-in-out;
     }
    }
  }
}
@keyframes animated-bg {
  from { background-position: 0 0; }
  to { background-position: -1000px -1000px; }
}
@keyframes drone {

  0%{
    transform: translateX(-900px);
  }

}