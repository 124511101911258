#header{
  min-height: 10vh;
  background: rgb(22,25,36) ;
  background: linear-gradient(90deg, rgba(22,25,36,1) 16%, rgba(5,4,5,0.9837184873949579) 45%, rgba(24,12,19,1) 100%, rgba(21,12,14,0.7428221288515406) 100%)     ;
  .header{
    display: flex;
    align-items: start;
    justify-content: center;
    &--bio{
      color: white;
      font-family: "Poor Richard",sans-serif;
      font-size: 20px;
      font-weight: 500;
      margin-right: 3%;
    }
    &--link{

      &__site{

        color: white;
        font-family: "Poor Richard",sans-serif;
        font-size: 20px;
        font-weight: 500;
      }
      a{
        margin: 20px 10px 0 10px;
      }
      &__instagram{

        color: white;

      }
      &__tiktok{
     img{
       border-radius: 50%;
       width: 20px;
       height: 20px;
     }
      }
      &__telegram{
       color: white;
      }
    }
  }
}
