@import "../src/style/home/hero";
@import "../src/style/Header/header";
@import "../src/style/Footer/Footer";
@import "../src/style/Biography/biography";
@import "../src/style/dance/dance";

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.container{
    width: 90%;
    margin: 0 auto;
}
a{
   text-decoration: none;
}

body,html{
    overflow-x: hidden;
}

